<template>
  <update-view-main :key="reRenderComponentKey" />
</template>

<script>
import UpdateViewMain from './index.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'UpdateView',
  components: {
    UpdateViewMain,
  },
  computed: {
    reRenderComponentKey() {
      return this.$store.state[this.MODULE_NAME].reRenderComponentKey
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
    }
  },
}
</script>
