<template>
  <div class="mb-2">
    <b-form>
      <validation-observer ref="createCreditNoteForm">
        <portal to="body-top">
          <h3 class="text-center font-weight-bolder mb-1">
            {{ $t('Request for Credit Note') }}
          </h3>

          <quote-status-bar
            :item="quote"
            :quote-status-bar="quoteStatusBar"
          />
        </portal>

        <supplier-information :supplier-id="122862" />
        <credit />
        <totals class="mt-1" />
        <form-bottom :form-refs="$refs" />
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import Credit from '@/views/main/orders/view/credit-note/components/Credit.vue'
import FormBottom from '@/views/main/orders/view/credit-note/components/FormBottom.vue'
import Totals from '@/views/main/orders/view/credit-note/components/Totals.vue'
import SupplierInformation from '@/views/main/orders/view/credit-note/components/SupplierInformation.vue'
import { BForm } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'UpdateViewMain',
  components: {
    BForm,
    SupplierInformation,
    Totals,
    FormBottom,
    Credit,
    QuoteStatusBar,
    ValidationObserver,
  },
  data() {
    return {
      theSupplierId: null,
    }
  },
  computed: {
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(({ data: { data } }) => {
      this.theSupplierId = data.customer_id
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        internal_order_reference: data.internal_order_notes,
        customer_id: data.customer_id,
        payment_term_id: data.payment_term,
        linked_order_id: data.linked_order_id,
        billing_contact: data.billing_contact,
        billing_email: data.billing_contact.email,
        billing_phone: data.billing_contact.phone_four,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_CREDIT_ITEMS_TABLE`, data.other_charges)
      this.$store.commit(`${this.MODULE_NAME}/SET_TOTALS_FORM`, {
        total_order_notes: data?.credit_note_order,
      })
    })
  },
  setup() {
    const { quoteStatusBar, MODULE_NAME } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
    }
  },
}
</script>
